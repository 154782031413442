<script setup lang="ts">
import { useDebounceFn } from '@vueuse/core';

/**
 * StickyFooter
 *
 * スクロールしても、常に画面下部に固定される位置にchildrenを置くPortalコンポーネント。
 * 複数StickyFooterが利用されても、縦に並んでレイアウトされるので、
 * stickyした要素が重なり合わない。
 * また、最下部までスクロールした時、ページ下部にStickyFooter分の高さが確保されているので、
 * Stickyしている要素が上からオーバーレイしてアクセスできない要素が発生しない。
 */

// fixedが最下部固定。aboveはfixedの上に表示。
// 通知など、出たり消えたりするものはaboveにする。
type Layer = 'fixed' | 'above';
// stretchは画面いっぱいに広がる。leftは左端に寄る。centerは中央に寄る。rightは右端に寄る。
// stretch以外は自分で要素のwidthを指定する必要がある。
type Align = 'stretch' | 'left' | 'center' | 'right';
const props = withDefaults(
  defineProps<{
    layer?: Layer;
    align?: Align;
  }>(),
  {
    layer: 'above',
    align: 'stretch',
  }
);
const slots = defineSlots<{
  default(): any[];
}>();

const style = useCssModule();

// 100vwはスクロールバーの幅も含んだ横幅になってしまうので、jsでスクロールバーの無い横幅を取得する
const widthInPxWithoutScrollbar = ref('100vw');
const handleResize = useDebounceFn(() => {
  if (import.meta.server || import.meta.env.NODE_ENV === 'test') return;
  widthInPxWithoutScrollbar.value = `${document.body.clientWidth}px`;
}, 20);
onMounted(() => {
  if (import.meta.server) return;
  handleResize();
  window.addEventListener('resize', handleResize);
});
onBeforeUnmount(() => {
  if (import.meta.server) return;
  window.removeEventListener('resize', handleResize);
});

const render = () => {
  const defaultSlots = slots.default();
  const injectClasses = [
    style.wrapper__inner,
    style[`wrapper__inner--${props.align}`],
  ]
    .filter(Boolean)
    .join(' ');

  return defaultSlots.map((slot) => {
    if (slot.props == null) {
      return slot;
    }

    return {
      ...slot,
      props: {
        ...slot.props,
        class: slot.props?.class
          ? `${slot.props.class} ${injectClasses}`
          : injectClasses,
      },
    };
  });
};

const destination = computed(() => {
  if (props.layer === 'fixed') {
    return '#sticky-footer';
  }
  return '#above-sticky-footer';
});
</script>

<template>
  <ClientOnly>
    <Teleport :to="destination">
      <div :class="[$style.wrapper, $style[`wrapper--${align}`]]">
        <render />
      </div>
    </Teleport>
  </ClientOnly>
</template>

<style lang="scss">
/**
 * CSSの実装方針について
 *
 * 1. ラッパーコンポーネントは全て `width: 0` で、下の要素に覆いかぶさらないようにする
 * 2. slotに入ったコンポーネントのmargin-leftとtranslateXで、align位置を調整する
 */
.sticky-footer {
  position: sticky;
  // stickyしている要素外へのクリックイベントが下の要素に伝わるように、DOMのwidthを0にする
  width: 0;
  bottom: 0;
  z-index: 1;
}

#sticky-footer {
  // stickyしている要素外へのクリックイベントが下の要素に伝わるように、DOMのwidthを0にする
  width: 0;
}

#above-sticky-footer {
  // stickyしている要素外へのクリックイベントが下の要素に伝わるように、DOMのwidthを0にする
  width: 0;
}
</style>

<style lang="scss" module>
.wrapper {
  &--stretch {
    width: v-bind(widthInPxWithoutScrollbar);
  }

  &--left {
    width: 0;
  }

  &--right {
    width: 0;
    margin-left: v-bind(widthInPxWithoutScrollbar);
  }

  &--center {
    width: 0;
    margin-left: calc(v-bind(widthInPxWithoutScrollbar) / 2);
  }

  &__inner {
    &--stretch {
      width: v-bind(widthInPxWithoutScrollbar);
    }

    &--left {
      transform: translateX(0);
    }

    &--right {
      transform: translateX(-100%);
    }

    &--center {
      width: auto;
      transform: translateX(-50%);
    }
  }
}
</style>
